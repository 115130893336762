const CardDefaultTheme = ({ children }) => {
  return (
    <div className="w-full rounded-lg bg-slate-300 shadow drop-shadow-sm shadow-black">
      <div className="w-full rounded-lg border-blue-sunny border-4 p-1 shadow-black shadow-inner bg-transparent">
        {children}
      </div>
    </div>
  );
};

export default CardDefaultTheme;
