import CardDefaultTheme from "@/components/cards/cardDefaultTheme";

const NotFoundPage = () => {
  return (
    <div className="container m-auto">
      <CardDefaultTheme>
        <div className="m-auto relative block text-center">
          <h4 className="font-bold text-9xl">404</h4>
          <span className="font-bold text-5xl">Page Not Found!</span>
        </div>
      </CardDefaultTheme>
    </div>
  );
};

export default NotFoundPage;
