// import ButtonDefault from "@/components/buttons/buttonDefault";
import CardDefaultTheme from "@/components/cards/cardDefaultTheme";
// import { setSelected } from "@/stateSlices/themeSlice";
// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { app } from "configs/firebase.config";
// import { neonCursor } from "https://unpkg.com/threejs-toys@0.0.8/build/threejs-toys.module.cdn.min.js";
// import { noisyLinesBackground } from "threejs-toys";

// noisyLinesBackground({
//   el: document.getElementById("root"),
//   colors: [0x0231c3, 0xa6d1f6],
//   minStroke: 0.5,
//   maxStroke: 2,
//   timeCoef: 0.0002,
//   coordScale: 2,
//   displacementScale: 0.02,
// });

const LoginPage = () => {
  // const [formUsed, setFormUsed] = useState("login");
  // const weathers = useSelector((state) => state.theme.weathers);
  // const selectedWeather = useSelector((state) => state.theme.selected);
  // const dispatch = useDispatch();
  const firebaseAuth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const emailProvider = new EmailAuthProvider();
  // neonCursor({
  //   el: document.getElementById("root"),
  //   shaderPoints: 16,
  //   curvePoints: 80,
  //   curveLerp: 0.5,
  //   radius1: 5,
  //   radius2: 30,
  //   velocityTreshold: 10,
  //   sleepRadiusX: 100,
  //   sleepRadiusY: 100,
  //   sleepTimeCoefX: 0.0025,
  //   sleepTimeCoefY: 0.0025,
  // });
  // const changeForm = (formName) => {
  //   setFormUsed(formName);
  // };

  // const weatherChanger = (e) => {
  //   e.preventDefault();
  //   dispatch(setSelected(e.target.value));
  // };

  const doLoginWithGoogle = async () => {
    const response = await signInWithPopup(firebaseAuth, googleProvider);
    // const response = await signInWithPopup(firebaseAuth, emailProvider);
    console.log(response);
  };

  return (
    <div className="justify-center text-white flex">
      <div className="md:w-2/3 w-full">
        <CardDefaultTheme>
          <div className="flex">
            <div
              // className={
              //   "w-fit " +
              //   (formUsed === "login" ? "brightness-100" : "brightness-90")
              // }
              onClick={() => doLoginWithGoogle()}
            >
              <CardDefaultTheme>Login</CardDefaultTheme>
            </div>
            {/* <div
              className={
                "w-fit " +
                (formUsed === "register" ? "brightness-100" : "brightness-90")
              }
              onClick={() => changeForm("register")}
            >
              <CardDefaultTheme>Register</CardDefaultTheme>
            </div> */}
          </div>
          {/* <div className="text-2xl p-4 justify-end flex font-bold">
            <ButtonDefault>{formUsed}</ButtonDefault>
          </div> */}
        </CardDefaultTheme>
        {/* <select name="weatherSelect" onChange={weatherChanger}>
          {weathers.map((weather) => {
            return (
              <option value={weather} key={weather}>
                {weather}
              </option>
            );
          })}
          <option value={"lah"}>lah</option>
        </select>
        <h1>{selectedWeather}</h1> */}
      </div>
    </div>
  );
};

export default LoginPage;
