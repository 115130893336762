import { initializeApp, getApp, getApps } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBoFrQw20ImUNCtULZ7G53uNZwNbcgg8aY",
  authDomain: "growstat-4ec57.firebaseapp.com",
  projectId: "growstat-4ec57",
  storageBucket: "growstat-4ec57.appspot.com",
  messagingSenderId: "641436525899",
  appId: "1:641436525899:web:3ad73050dfd5ddb904b4ce",
  measurementId: "G-MPKY05Y1N1",
};

const app = getApps.length ? getApp() : initializeApp(firebaseConfig);

export { app };
