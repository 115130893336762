import { Route, Routes } from "react-router-dom";
import "./App.css";
import NotFoundPage from "./pages/notFoundPage";
import LoginPage from "./pages/loginPage";

function App() {
  return (
    <div className="" id="app">
      <div className="" id="cursor-area">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
