import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    weathers: ["sunny", "night", "neon"],
    selected: "sunny",
  },
  reducers: {
    setWeathers: (state, action) => {
      state.weathers = action.payload;
    },
    setSelected: (state, action) => {
      if (state.weathers.find((element) => element === action.payload)) {
        state.selected = action.payload;
      }
    },
  },
});

export const { setWeathers, setSelected } = themeSlice.actions;

export default themeSlice.reducer;
